// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();
// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;

// @codekit-prepend "cycle2/jquery.cycle2.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.swipe.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.autoheight.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.center.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.scrollVert.js" quiet;

// -----------------------------------------------------------------------------------------
jQuery.noConflict();
(function($) {

	$(document).on('click', '.hamburger', function() {
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
	});

	function closeNav() {
		$('body').removeClass('bodyfix');
		$('.hamburger').removeClass('is-active');
		$('header nav').removeClass('is-active');
	}

	// close the menu with ESC key
	$(document).on('keyup', function(evt) {
		if (evt.keyCode == 27) {
			closeNav();
		}
	});

	// close menu on click outside of the nav
	$(document).click(function(event) {
		var target = $(event.target);
		if (!target.closest('#main-nav').length && !target.closest('.hamburger').length) {
			closeNav();
		}
	});

	// ------------------------------------------------------------------
	// intro 

	$(document).on('click', 'button.lang-en', function() {
		$(this).closest('.lang-group-item').find('lang-de').prop('aria-expanded', false);
		$(this).prop('aria-expanded', true);

		$(this).closest('.lang-group-item').find('lang-de').removeClass('active-lang');
		$(this).addClass('active-lang');

		$(this).closest('.lang-group-item').find('article[lang="de"]').addClass('lang-hidden');
		$(this).closest('.lang-group-item').find('article[lang="en"]').removeClass('lang-hidden');
	})


	$(document).on('click', 'button.lang-de', function() {
		$(this).closest('.lang-group-item').find('lang-en').prop('aria-expanded', false);
		$(this).prop('aria-expanded', true);

		$(this).closest('.lang-group-item').find('lang-en').removeClass('active-lang');
		$(this).addClass('active-lang');

		$(this).closest('.lang-group-item').find('article[lang="en"]').addClass('lang-hidden');
		$(this).closest('.lang-group-item').find('article[lang="de"]').removeClass('lang-hidden');
	})

	// ------------------------------------------------------------------
	// events  

	$('#events').on('click', '.show-details', function() {
		var status = $(this).prop('aria-expanded');
		$(this).prop('aria-expanded', !status); // toggle with status 
		$(this).closest('.details-wrapper').find('.details').slideToggle();

		$(this).find('.close').toggleClass('hidden');
		$(this).find('.open').toggleClass('hidden');

		// Close siblings
		$('.show-details').not(this).each(function() {
			$(this).prop('aria-expanded', false);
			$(this).closest('.details-wrapper').find('.details').slideUp();
			$(this).find('.read-more').addClass('hidden');
			$(this).find('.read-less').removeClass('hidden');
		});
	});


	$('#toggle-text').on('click', function() {
		var status = $(this).prop('aria-expanded');
		$(this).prop('aria-expanded', !status); // toggle with status 

		$(this).find('.read-more').toggleClass('hidden');
		$(this).find('.read-less').toggleClass('hidden');


		$('#copy-trimmed').slideToggle();
		$('#copy-full').slideToggle();

	});




	// ------------------------------------------------------------------


})(jQuery);
// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
})();
// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;

// @codekit-prepend "cycle2/jquery.cycle2.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.swipe.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.autoheight.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.center.js" quiet;
// @codekit-prepend "cycle2/jquery.cycle2.scrollVert.js" quiet;
// @codekit-prepend "isotope.js" quiet;
// @codekit-prepend "packery-mode.pkgd.js" quiet;
// @codekit-prepend "imagesloaded.pkgd.js" quiet;

// -----------------------------------------------------------------------------------------
jQuery.noConflict();
(function($) {

	$(document).on('click', '.hamburger', function() {
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
	});

	function closeNav() {
		$('body').removeClass('bodyfix');
		$('.hamburger').removeClass('is-active');
		$('header nav').removeClass('is-active');
	}

	// close the menu with ESC key
	$(document).on('keyup', function(evt) {
		if (evt.keyCode == 27) {
			closeNav();
		}
	});

	// close menu on click outside of the nav
	$(document).click(function(event) {
		var target = $(event.target);
		if (!target.closest('#main-nav').length && !target.closest('.hamburger').length) {
			closeNav();
		}
	});

	// ------------------------------------------------------------------
	// intro 

	$(document).on('click', 'button.lang-en', function() {
		$(this).closest('.lang-group-item').find('lang-de').prop('aria-expanded', false);
		$(this).prop('aria-expanded', true);

		$(this).closest('.lang-group-item').find('lang-de').removeClass('active-lang');
		$(this).addClass('active-lang');

		$(this).closest('.lang-group-item').find('article[lang="de"]').addClass('lang-hidden');
		$(this).closest('.lang-group-item').find('article[lang="en"]').removeClass('lang-hidden');
	})


	$(document).on('click', 'button.lang-de', function() {
		$(this).closest('.lang-group-item').find('lang-en').prop('aria-expanded', false);
		$(this).prop('aria-expanded', true);

		$(this).closest('.lang-group-item').find('lang-en').removeClass('active-lang');
		$(this).addClass('active-lang');

		$(this).closest('.lang-group-item').find('article[lang="en"]').addClass('lang-hidden');
		$(this).closest('.lang-group-item').find('article[lang="de"]').removeClass('lang-hidden');
	})

	// ------------------------------------------------------------------
	// events  

	$('#events').on('click', '.show-details', function() {
		var status = $(this).prop('aria-expanded');
		$(this).prop('aria-expanded', !status); // toggle with status 
		$(this).closest('.details-wrapper').find('.details').slideToggle();

		$(this).find('.close').toggleClass('hidden');
		$(this).find('.open').toggleClass('hidden');

		// Close siblings
		$('.show-details').not(this).each(function() {
			$(this).prop('aria-expanded', false);
			$(this).closest('.details-wrapper').find('.details').slideUp();
			$(this).find('.read-more').addClass('hidden');
			$(this).find('.read-less').removeClass('hidden');
		});
	});


	$('#toggle-text').on('click', function() {
		var status = $(this).prop('aria-expanded');
		$(this).prop('aria-expanded', !status); // toggle with status 

		$(this).find('.read-more').toggleClass('hidden');
		$(this).find('.read-less').toggleClass('hidden');


		$('#copy-trimmed').slideToggle();
		$('#copy-full').slideToggle();

	});



	// ------------------------------------------------------------------
	// blog load more

	function blog_posts_ajax(action_type, category_term_ids, current_page) {
		const data = {
			'action': 'blog_posts_ajax',
			'action_type': action_type,
			'nonce': ajax_object.nonce,
			'query': ajax_object.blog_posts,
			'page': current_page,
			'category_term_ids': category_term_ids,
		}

		$.ajax({
			url: ajax_object.ajax_url,
			data: data,
			type: 'POST',
			beforeSend: function(xhr) {
				$('#blog-loader').removeClass('hidden');
				$('.blog-load-more-wrapper').addClass('hidden');
			},
			success: function(response) {
				var data_object = JSON.parse(response);

				$('#blog-loader').addClass('hidden');

				if (data_object.teasers_html) {
					$('#blog-teaser').append(data_object.teasers_html);
					$('.blog-load-more-wrapper').removeClass('hidden');
					ajax_object.current_page++;

					if (ajax_object.current_page >= data_object.max_num_pages) {
						$('.blog-load-more-wrapper').addClass('hidden');
					}
				} else {
					$('.blog-load-more-wrapper').addClass('hidden');
				}
			}
		});
	}

	// ------------------------------------------------------------------
	$('#blog-load-more').on('click', function(e) {
		e.preventDefault();
		let category_term_ids = $('#blog-categories .selected').data('term-ids') || ajax_object.all_blog_term_ids; // get term id(s) from selected filter, without filter all from ajax_object(enqueue localize)
		let current_page = ajax_object.current_page;
		let action_type = 'load_more'; // Set action_type to 'load_more'

		blog_posts_ajax(action_type, category_term_ids, current_page);
	});

	// ------------------------------------------------------------------
	// blog category filter (for the first page, then the load more does the rest)
	$('#blog-categories').on('click', 'li', function() {

		if ($(this).hasClass('selected')) {
			return
		}

		var category_term_ids = $(this).data('term-ids');
		if (category_term_ids) {
			$('#blog-categories .clear').removeClass('hidden');
		}

		if ($(this).hasClass('clear')) {
			$('#blog-categories li').removeClass('selected');
			$('#blog-categories .clear').addClass('hidden');
			category_term_ids = ajax_object.all_blog_term_ids; // get term id(s) from selected filter, without filter all from ajax_object (enqueue localize)
			ajax_object.current_page = 1;
		}

		$(this).addClass('selected');
		$(this).siblings().removeClass('selected');

		let current_page = 1;
		let action_type = 'filter';

		$('#blog-teaser').empty(); // remove unfiltered posts  

		blog_posts_ajax(action_type, category_term_ids, current_page);

	});


	// ------------------------------------------------------------------
	// blog flex image gallery with isotope

	var $grid = $('.image-gallery .grid').isotope({
		// set itemSelector so .grid-sizer is not used in layout
		itemSelector: 'figure',
		percentPosition: true,
		layoutMode: 'packery',
		packery: {
			columnWidth: '.grid-sizer',
			gutter: 0,
		},
	})


	$grid.find('img').on('load', function() {
		$grid.isotope('layout');
	})


	// layout Isotope after each image loads
	$grid.imagesLoaded().progress(function() {
		$grid.isotope('layout');
	});




})(jQuery);